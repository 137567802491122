import React from 'react'
import { Row, Col, } from 'react-bootstrap'
import { Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'


function HygieneSanitation() {
  return (
    <div>
       <Helmet>
        <title>
          Hygiene and Sanitation
        </title>
      </Helmet>
      <Link to='/' className='btn btn-light my-3'>Go Home</Link>
      
      <Row>
          <Row>
          <h2 className='text-center'>Hygiene and sanitation</h2>
          <hr style={{
              background: 'white',
              color: 'white',
              height: '3px',
            }}/>
        <h4>Cleaning in place (CIP)</h4>
        <p>Cleaning the food and beverages equipment without dismantling them using the
        variety of chemistry like alkali, acids and the biocides to achieve the desired
        cleaning.</p>  
          </Row>
          <Row>
          <Col >
              <img src={require('../images/CIP-2-capabilities.png')} height={150} width={250} alt=''/>                  
          </Col>
          
          <Col >
              <img src={require('../images/Clean-in-Place.png')} height={160} width={250} alt=''/>                   
          </Col>
          <hr style={{
              background: 'white',
              color: 'white',
              height: '3px',
            }}/>
          </Row>       
          
                 
        </Row>
        
        <Row >
          <Row>
              <h4>Bottle washing</h4>
            <p>Cleaning of the returnable bottles and drums from the market using the formulated
            alkaline solutions and some biocides to give the bottle a shiny and reusable
            characteristic.</p>  
          </Row>
          <Row>
          <Col >
              <img src={require('../images/bootlecleaning.jpeg')} height={150} width={250} alt=''/>                   
          </Col>
          
          <Col >
              <img src={require('../images/bottle1.jpg')} height={150} width={250} alt=''/>                    
          </Col>
          <hr style={{
              background: 'white',
              color: 'white',
              height: '3px',
            }}/>
          </Row>       
                   
        </Row>
        
        <Row >
          <Row>
          <h4>Conveyor lubrication</h4>
            <p>Different chain lubrications are offered for a specific use either synthetic, dry
            lubrications (natural oil) and natural soap lubricant.</p>  
          </Row>
          <Row>
          <Col>
              <img src={require('../images/RS - Chain Lubrication (3).jpeg')} height={150} width={250} alt=''/>                   
          </Col>
          
          <Col >
              <img src={require('../images/RS - Chain Lubrication (1).jpg')} height={150} width={250} alt=''/>                     
          </Col>
          <hr style={{
              background: 'white',
              color: 'white',
              height: '3px',
            }}/>
          </Row>                
        </Row>


        <Row >
          <Row>
            <h4>Open plant cleaning (OPC)</h4>
          <p>Different range of alkali, acidic and biocides used to clean the surrounding
          environment of the food and beverages plants to give them the “feel and look”
          appearance. Foam equipment are supplied at a variety according to the customer
          specification.</p>  
          </Row>
          <Row>
          <Col>
              <img src={require('../images/openplant.jpg')} height={160} width={250} alt=''/>                   
          </Col>
          
          <Col >
              <img src={require('../images/openplantcleaning.jpg')} height={160} width={250} alt=''/>                     
          </Col>
          <hr style={{
              background: 'white',
              color: 'white',
              height: '3px',
            }}/>
          </Row>                
        </Row>

        <Row >
          <Row>
                  <h2 className='text-center'>More Services</h2>
            <hr style={{
                background: 'white',
                color: 'white',
                height: '3px',
              }}/>
          <br />
            <li>Organizational improvement focusing on GMP’s</li>
            <br />
            <li>Improving the SOP’s</li>
            <br />
            <li>Partnering with the customer and taking hygiene as our KPI</li>
            <br />
            <li>Offering training to the stakeholders of the organization</li>
            <br />
            <li>Hygiene auditing and detailed reporting</li>
            <br /><br />
       
          </Row>
          <hr style={{
              background: 'white',
              color: 'white',
              height: '3px',
            }}/>
          </Row>
                        
    </div>
  )
}

export default HygieneSanitation