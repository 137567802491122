import React from 'react'
import { Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'

function AboutUsScreen() {
  return (
    <div>
      <Helmet>
        <title>
          About Us
        </title>
      </Helmet>
      <Link to='/' className='btn btn-light my-3'>Go Home</Link>
      <h1 className='text-center'>
      About Kayleego
      </h1>
      <hr  style={{
          background: 'white',
          color: 'white',
          height: '3px',
        }} />
        <p>Kayleego Holdings (Pty) Ltd is a proud South African provider of water and hygiene
        solutions together with respective services. We thrive to provide our customers with
        sustainable solutions that guarantee protection of equipment integrity and the safety
        &amp; health of our customers.<br />
        <br />Through our channel partnerships, Kayleego Holdings (Pty) Ltd provides world class
        chemicals and equipment for water treatment and hygiene services. We are a proud
        supplier for water treatment chemicals and equipment for cooling system, boiler
        water, flocculation, and disinfection of potable and effluent systems. Through our
        engineering partners, we provide dosing equipment, filtration systems, blowdown
        systems, flocculation and disinfection.<br />
        <br />We provide world class hygiene solutions through our range of CIP chemicals, OPC
        chemicals together with their respective services like CIP validations, CIP &amp; OPC
        training and automation of the systems. As a proudly South African company, we
        pride ourselves as the supplier of choice through integrity.
        </p>
        <hr style={{
          background: 'white',
          color: 'white',
          height: '3px',
        }}/>
        <h2 className='text-center'>Our Mission</h2>
        <hr style={{
          background: 'white',
          color: 'white',
          height: '3px',
        }}/>
        <p>At Kayleego Holdings (Pty) Ltd, we strive to understand the customer’s processes so
          that we apply customized water and hygiene solutions that solve problems with the
          right people, the right experience and the right technology. We provide solutions that
          drive sustainability by reducing water and energy costs through plant optimization.</p>
        <hr style={{
          background: 'white',
          color: 'white',
          height: '3px',
        }}/>
        <h2 className='text-center'>Our Vision</h2>
        <hr style={{
          background: 'white',
          color: 'white',
          height: '3px',
        }}/>
        <p>The success of our customers is integral to our business. It is therefore our vision to
          facilitate the success of our customers through world class innovative water and
          hygiene technologies.</p>
    </div>
  )
}

export default AboutUsScreen