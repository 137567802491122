import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

function Footer() {
  return (
    <footer>
        <Container>
            <Row>
              <Col className='text-center'>CopyRight &copy; Kayleego Holdings
             
              
              </Col>
              
            </Row>
            <Row>
              <Col className='text-center'>
              Handcrafted {'&'} Maintained by Tmosh IT Solutions
              
              </Col>
              
            </Row>
            
        </Container>
    </footer>
  )
}

export default Footer