import React from 'react'
import {Helmet} from 'react-helmet'
import { Link} from 'react-router-dom'


function Coal() {
    return(

        <div>
            
            <Helmet>
                <title>
                    Coal
                </title>
            </Helmet>
            <Link to='/' className='btn btn-light my-3'>Go Home</Link>
            <div
                style={{
                    backgroundImage: `url(${require("../images/coal-background2.jpg")})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <h1 className='text-center' style={{ color: 'white' }} >Coal for industrial and agricultural use</h1>

                    <hr  style={{
                    background: 'white',
                    color: 'white',
                    height: '3px',
                    }} />

                <p  style={{fontSize:'22px', fontWeight: 'bold', color: 'white'}}>At Kayleego we work closely and directly with various mines in Africa.
                    <br /><br />
                Our different coal is selected depending on the specific specs required by our clients.
                <br /><br />
                We sell only to the end-user in various industries including: manufacturing, textiles, paper, food & beverage, animal feed, agro-processing, abattoirs, nurseries, and chicken farms....
                <br /><br />
                Different grades are available depending on our client's requirements.</p>
                <br /> 

                <p  style={{ fontSize:'23px', fontWeight: 'bold', color: 'white'}}>Size available:</p>

                <li  style={{fontWeight: 'bold', color: 'white'}} >Duff</li> 

                <li style={{fontWeight: 'bold', color: 'white'}}>Grains</li>

                <li  style={{fontWeight: 'bold', color: 'white'}}>Peas</li>

                <li  style={{fontWeight: 'bold', color: 'white'}}>Small Nuts</li>

                <li  style={{fontWeight: 'bold', color: 'white'}}>Large Nuts</li>

               
                <br />
            </div>
            
        </div>
    )
}

export default Coal