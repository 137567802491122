import React from 'react'
import { Link} from 'react-router-dom'
import { BsFillEnvelopeFill,BsFillTelephoneFill,BsFillGeoAltFill } from "react-icons/bs";
import { Card, Row, Col } from 'react-bootstrap';
import {Helmet} from 'react-helmet'


function ContactUs() {
  return (
    <div>
      <Helmet>
        <title>
          Contact Us
        </title>
      </Helmet>
        <Link to='/' className='btn btn-light my-3'>Go Home</Link>
        <h1 >
            Contact Us
        </h1>

        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>
                  <BsFillGeoAltFill />
                </Card.Title>
                <Card.Text>
                    Unit 38 Allendale Industrial
                    Business Park, 917 Morkels
                    CL, Halfway House, Midrand,
                    1685
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
                <Card.Body>
                  <Card.Title>
                    <BsFillTelephoneFill />
                  </Card.Title>
                  <Card.Text>
                    012 006 5211
                  </Card.Text>
                </Card.Body>
              </Card>
          </Col>

          <Col>
              <Card>
                  <Card.Body>
                    <Card.Title>
                      <BsFillEnvelopeFill />
                    </Card.Title>
                    <Card.Text>
                    info@kayleego.co.za
                    </Card.Text>
                  </Card.Body>
                </Card>
              
          </Col>  
        </Row>
    </div>
  )
}

export default ContactUs