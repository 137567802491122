import React from 'react'
import { Link} from 'react-router-dom'
import { Row, Col, } from 'react-bootstrap'
import {Helmet} from 'react-helmet'


function WaterTreatement() {
  
  
  return (
    <div>
       <Helmet>
        <title>
          Water Treatement
        </title>
      </Helmet>
      <Link to='/' className='btn btn-light my-3'>Go Home</Link>
     
      <Row>
          <Row>
          <h2 className='text-center'>Water Treatment</h2>
          <hr style={{
              background: 'white',
              color: 'white',
              height: '3px',
            }}/>
                <ul>
                  <h4>Water treatment chemicals</h4>
                  <p>
                  Kayleego Holdings chemicals Treatment Technologies provides a wide range of chemicals for a variety of applications. 
                  Below are the chemicals groups classified by functionality.</p>

                  <ul>
                    <li>Corrosion inhibitors</li>
                    <li>Scale inhibitors</li>
                    <li>Disinfectants</li>
                    <li>Coagulants</li>
                    <li>Boiler water chemicals</li>
                    <li>pH conditioners</li>
                    <li>Biocides</li>
                    <li>Antifoams</li>
                    <li>Oxygen Scavengers</li>
                    <li>Flocculants</li>
                    <li>Algaecides</li>
                  </ul> 
                  <h4>Corrosion Inhibitors</h4> 

                    <p>Leads to failure of critical parts of boiler systems, deposition of corrosion products in critical heat exchange areas, 
                    and overall efficiency loss. Corrosion inhibitors are chemicals applied to protect the metals surface from corrosion by creating a 
                    thin protective layer on the metal. There are different kinds of corrosion inhibitors and are classified by how they prevent or inhibit corrosion.</p> 
                    <p>such as chromate, nitrite, nitrate are called oxidising anions, phosphate and molybdate are called non-oxidising anions. 
                    These inhibit corrosion by shifting the corrosion potential forcing the metal surface into a passive state.   Cathodic inhibitors such
                    as calcium, zinc, or magnesium protect the metal by forming oxide layers on the metal surface. Organic inhibitors are adsorbed onto the
                      metal surface forming a hydrophobic film that prevents corrosion.</p> 
                    <p>Precipitation inducing inhibitors are compounds that cause the formation of precipitates on the surface of the metal, thereby providing a protective film.
                    The most common inhibitors of this category are silicates and phosphates. Volatile Corrosion Inhibitors are compounds
                    transported in a closed environment to the site of corrosion by volatilisation from a source. Examples are morpholine and 
                    hydrazine and volatile solids such as salts of dicyclohexylamine, cyclohexylamine and hexamethylene-amine. On contact with 
                    the metal surface, the vapour of these salts condenses and is hydrolysed by moist environment, to liberate protective ions.
                          </p>

                    <h4>Scale inhibitors</h4>

                    <p>Is the deposit that forms on surfaces of heat exchangers when the solubility of the dissolved salts has been exceeded as the 
                    temperature increases. Some examples of scale are calcium and magnesium carbonates, sulphates, and silicate. Scale inhibitors are negatively 
                    charged polymers which attach themselves to the crystal structure of the scale disrupting the formation of the scale. The scale then remains in suspension.
                    Some of the examples of scale inhibitors are phosphate esters, phosphoric acid and solutions of low molecular weight polyacrylic acid. </p> 

                    <h4>Disinfectants</h4>

                    <p>These are chemicals used to kill unwanted micro-organisms in water. There are different types of disinfectants namely:</p>
                    <ul>
                      <li>Chlorine (dose 2-10 mg/L)</li>
                      <li>Chlorine dioxide</li>
                      <li>Hypochlorite</li>
                      
                    </ul>

                    <h4>Chlorine</h4>

                    <p>Chlorine kills bacteria and viruses by breaking down the chemical bonds in their molecules. 
                    However, the effectiveness of chlorine as a disinfectant is depended on pH. The most effective pH range for chlorine 
                    is between 5.5 – 7.5 above which it is ineffective.   When chlorine enters water, it forms hypochlorous acid which is an
                    electrically neutral. Because the molecule of pathogens is negatively charged, it can only be penetrated by the neutral 
                    hypochlorous acid. However, chlorine reacts with ammonia, organics in water which forms chloramines and other compounds before
                      free chlorine is available for disinfection.</p>

                    <h4>Chlorine dioxide disinfection</h4>

                    <p>ClO2 is used principally as a primary disinfectant for surface waters with odour and taste problems. It is an effective biocide at 
                    concentrations as low as 0.1 ppm and over a wide pH range. ClO2 penetrates the bacterial cell wall and reacts with vital amino acids
                    in the cytoplasm of the cell to kill the organisms. The by-product of this reaction is chlorite. 
                    Chlorine dioxide disinfects according to the same principle as chlorine, however, as opposed to chlorine, 
                    chlorine dioxide has no harmful effects on human health and is easy to apply and manage. </p>

                    <h4>Hypochlorite disinfection</h4>

                    <p>is applied in the same way as chlorine dioxide and chlorine. Hypo chlorination is a disinfection
                    method that is not used widely anymore, since an environmental agency proved that the Hypochlorite for disinfection 
                    in water was the cause of bromate consistence in water.</p> 

                    <h4>Algaecides</h4>

                    <p>These are the chemicals applied to kill algae. </p>

                    <h4>Antifoams</h4>

                    <p>Antifoam blends contain oils combined with small amounts of silica which break down foam due to two of silicone
                    properties namely, incompatibility with aqueous systems and ease of spreading. Antifoam compounds are available
                      either as powder or as an emulsion of the pure product – polydimethylsiloxane. The antifoams are chemically
                      inert and do not react with the medium that is defoamed. They are odourless, tasteless, non-volatile,
                        non-toxic and they do not corrode materials.</p>

                    <h4>Boiler water chemicals</h4>
                    <p>Chemicals supplies chemicals for boiler treatment for the following applications:</p> 
                    <ul>
                      <li>Oxygen Scavenging</li>
                      <li>Scale Inhibition</li>
                      <li>Corrosion Inhibition</li>
                      <li>Antifoaming</li>
                      <li>Alkalinity Control</li></ul>

                    <h4>Coagulants</h4>
                    <p>High valence positive ions such as aluminium and iron are preferred as either aluminium sulphate or ferric chloride.
                    Coagulation is very dependent on the doses of coagulants, the pH and colloid concentrations. To adjust pH levels Ca(OH)2
                      is applied as co-flocculent. Doses usually vary between 10 and 90 mg Fe3+/ L, but when salts 
                      are present a higher dose needs to be applied. </p>

                    <h4>Flocculants</h4>

                    <p>To promote the formation of flocs in water that contains suspended solids polymer flocculants (polyelectrolytes) are 
                    applied to promote bonds formation between particles. These polymers have a very specific effect, dependent upon their charges,
                    their molar weight and their molecular degree of ramification. The polymers are water-soluble and their molar weight varies between
                      105 and 106 g/ mol. There can be several charges on one flocculent. There are cationic polymers, based on nitrogen, anionic polymers, 
                      based on carboxylate ions and polyampholytes, which carry both positive and negative charges.</p>

                    <h4>Oxygen scavengers</h4>

                    <p>Oxygen scavenging refers to the removal of oxygen from the water to prevent corrosion.
                    Oxygen scavengers include both volatile products, such as hydrazine (N2H4) or other organic products like carbohydrazine
                    , hydroquinone, diethylhydroxyethanol, methylethylketoxime, but also non-volatile salts, such as sodium sulphite (Na2SO3).</p>
                                      </ul>  
          </Row>
  
          <Row>
          <Col >
          <img src={require('../images/waterchem.jpg')} height={150} width={250} alt=''/>                  
          </Col>
          
          <Col >
          <img src={require('../images/waterchem2.jpg')} height={150} width={250} alt=''/>                    
          </Col>
          <br />
          <hr style={{
              background: 'white',
              color: 'white',
              height: '3px',
            }}/>

          </Row>                
        </Row>
        

    <Row >
          <Row>  
          <h4>Feedwater / make-up water pre-treatement</h4>
          <p>Kayleego Holdings understands that the steam generation facility is the heart of any plant be it power plant, 
        manufacturing, breweries or otherwise and treatment of feedwater has become the key success key indicator 
        for steam processes. Likewise, the success of any other processes such as potable and effluent treatment as 
        well as reverse osmosis is dependant entirely on successful pre-treatment process and Kayleego Holdings provides the 
        necessary chemicals and equipment such as:</p>
        <ul>
          <li>Systems</li> 
          <li>Coagulation & Flocculation systems for liquid-solid separation</li>
          <li>Disinfection technology and products.</li>
        </ul>
          </Row>
          <Row>
          <Col >
              <img src={require('../images/water1.jpeg')} height={150} width={250} alt=''/>                  
          </Col>
          
          <Col >
              <img src={require('../images/water2.png')} height={200} width={250} alt=''/>                   
          </Col>
          <hr style={{
              background: 'white',
              color: 'white',
              height: '3px',
            }}/>
          </Row>               
    </Row>
        
    <Row >
        <Row>
          <h4>Boiler water treatment</h4>
                <p>
                Kayleego Holdings understands the importance of steam generating systems and how they contribute to the customer’s bottom line. 
                  Good boiler operating practices means reduced costs and prolonged production times and increased profitability.
                  Kayleego Holdings manufactures through its partners and supplies a wide range of Boiler Water Treatment Chemicals for 
                  variety of boilers of various operating pressure. These Boiler Water Treatment Chemicals are made from refined 
                  raw materials, latest manufacturing techniques and modern machines to ensure reliability 
                  of the product for the suitable application.</p>
                  <p>Years in the water treatment have taught us at Kayleego Holdings that there are five critical factors why boiler treatment is essential.</p>
                
                  <li>Water contains dissolved salts, which upon evaporation of water form scale on the heat transfer surfaces causing overheating of tubes and eventual failure.</li>
                  <li>Low pH and dissolved oxygen in the water cause corrosion which manifest as pitting, lowering the thickness of the boiler tubes, leading to rupture of the boiler tubes.</li>
                  <li>High total dissolved salts in the boiler will reduce the solubility of some compounds such as carbonates and sulphates of calcium and magnesium causing deposit formation.</li>
                  <li>Impurities carried over in the steam will cause deposit formation on turbine blades leading to reduced turbine efficiency, high vibrations, and blade failure.</li>
                  <li>These contaminants can also cause erosion of turbine blades. Silica at higher operating pressures volatilizes and carries over to the turbine blades.</li>

                <p>Chemicals thus provides chemicals, equipment and technology that ensure the efficient operation of boilers to prolong 
                their life span, reduce maintenance costs and energy bills. The company provides;</p> 
                  <li>sulphite and volatile oxygen scavengers for different applications</li> 
                  <li>Solubilising and non-solubilising scale and corrosion inhibitors</li> 
                  <li>Condensate treatment</li>
        </Row> 
       

        <Row>
          <Col >
            <img src={require('../images/boiler.jpg')} height={150} width={250} alt=''/>                   
          </Col>
          
          <Col >
            <img src={require('../images/boiler2.jpg')} height={150} width={250} alt=''/>                     
          </Col>
          <hr style={{
              background: 'white',
              color: 'white',
              height: '3px',
            }}/>
        </Row>              
    </Row>   


    <Row >
      <Row>
        <h4>Effluent water treatment</h4>
        <p>
          Effluent treatment has become one of the modern critical matters affecting industry especially in the wake the current global drive towards sustainability.
          Good corporate governance is now demanding companies to use processes that do not pollute or harm the environment 
          in which they do business. Unfortunately, effluent treatment tends to be a cost and Kayleego Holdings has a 
          revolutionary product that enhances natural degradation of organic waste to water and gases and comes at competitive price.
            We also provide coagulants, flocculants, dewatering/filter aids as well as disinfection with
            the current treatment of choice – chlorine dioxide.</p>
            
            <p>Kayleego Holdings’s professionally trained water treatment experts always try to offer effective Effluent Treatment 
            and Sewage Treatment Solutions that are customized to the needs of the customer. Our treatment solutions ensure that regulatory
            requirements are met at a fraction of the cost.</p>
            <p>The effluent after treatment can be further treated by passing through sand filter and carbon filter to take away organics and disinfected for 
            re-use depending on the intended use.</p>
      </Row>
          <Row>
          <Col >
            <img src={require('../images/effluent.jpg')} height={150} width={250} alt=''/>                  
          </Col>
          
          <Col >
            <img src={require('../images/Effluent-Water-Recovery-Pipe1.jpg')} height={150} width={250} alt=''/>                    
          </Col>
          <hr style={{
              background: 'white',
              color: 'white',
              height: '3px',
            }}/>
          
          </Row>              
    </Row>


        <Row >
          <Row>
          <h4>Open and closed circuit cooling water treatment</h4>
          <p>
          Kayleego Holdings manufactures and supplies cooling water treatment chemicals for variety of cooling 
            systems and water characteristics. Our range of cooling water treatment chemicals such as anti-scalants 
            and corrosion inhibitors cover open and closed circuit systems, hot and cold. Kayleego Holdings performs water 
            quality testing to ensure that the treatment program prescribed suits the customer’s needs.</p>
            
            <p>systems are also notorious for culturing bacteria which will cause corrosion. 
            Some bacteria like legionella which inhabit in cooling towers and condensers and are inhaled as droplets
            of water cause pneumonia which could be fatal. Kayleego Holdings provides biocides that control 
            the proliferation of bacteria in cooling systems.</p> 
        
            <p>Kayleego Holdings provides chemicals, equipment and technology for the treatment of open and closed circuit
            cooling water systems that ensure that there cooling tower packing is free of scale, corrosion and bacteria.</p> 
          
            <ul>
              <li>Scale & Corrosion Inhibitors for different water types.</li> 
              <li>Biocides for control of microbiological proliferation.</li> 
              <li>Chlorine dioxide generators</li> 
              <li>Dispersants for removal and dispersion of organic contaminants.</li> 
          </ul>  
          </Row>
          <Row>
          <Col >
            <img src={require('../images/coolingtowe1.jpg')} height={150} width={250} alt=''/>                   
          </Col>
          
          <Col >
            <img src={require('../images/coolintower.jpg')} height={150} width={250} alt=''/>                    
          </Col>
          <hr style={{
              background: 'white',
              color: 'white',
              height: '3px',
            }}/>
          
          </Row>              
        </Row> 


        <Row >
          <Row>
            <h4>Reverse osmosis membranes</h4>
            <p>
            Kayleego Holdings supplies membrane elements from most leading membrane producers. In our scope of supply you find tap, 
              brackish and seawater elements and all you have to do is tell us your type of water and we supply the relevant membranes. 
              We can even send our technical experts to assess your needs and recommend accordingly.
            </p>  
          </Row>
          <Row>
          <Col >
            <img src={require('../images/reverseosmosis.jpeg')} height={150} width={250} alt=''/>                  
          </Col>
          
          <Col >
            <img src={require('../images/Reverse-Osmosis-Membrane-Cleaning.jpg')} height={150} width={250} alt=''/>                   
          </Col>
          <hr style={{
              background: 'white',
              color: 'white',
              height: '3px',
            }}/>
          
          </Row>              
        </Row>           
    </div>
  );
}

export default WaterTreatement