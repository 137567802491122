import { Container } from 'react-bootstrap'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import AboutUsScreen from './screens/AboutUsScreen'
import MarketScreen from './screens/MarketScreen'
import ContactUs from './screens/ContactUs'
import WaterTreatement from './screens/WaterTreatement'
import HygieneSanitation from './screens/HygieneSanitation'
import Coal from './screens/Coal'


function App() {
  return (
    <Router>
      <Header />
      <main className="py-3">
        <Container>
          <Routes>
            <Route path='/' element={<HomeScreen/>} exact />
            <Route path='/about-us' element={<AboutUsScreen/>} />
            <Route path='/markets' element={<MarketScreen/>} />
            <Route path='/contact-us' element={<ContactUs/>} />
            <Route path='/water-treatement' element={<WaterTreatement/>} />
            <Route path='/hygiene-sanitation' element={<HygieneSanitation/>} />
            <Route path='/coal-kayleego' element={<Coal/>} exact />
          </Routes>
        </Container>
      </main>
      <Footer />
    </Router>
  );
}

export default App;