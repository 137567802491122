import React from 'react'
import {Helmet} from 'react-helmet'
import {Carousel, Container} from 'react-bootstrap'

function HomeScreen() {
  return (
    <div>
       <Helmet>
        <title>
          Home
        </title>
      </Helmet>
        
      
      <Container>
      <Carousel>
  <Carousel.Item interval={1500}>
    <img
      className="d-block w-100"
      src={require('../images/slide1.jpg')} height={450} width={700}
      alt="First slide"
    />
    <Carousel.Caption>
      <h2 class="font-weight-bold" style={{
        
        color: 'white'}}>We are fully commited to being the service provider of choice.</h2>
    </Carousel.Caption>
  </Carousel.Item>


  <Carousel.Item interval={1000}>
    <img
      className="d-block w-100"
      src={require('../images/slide2.jpg')} height={450} width={700}
      alt="Second slide"
    />
  </Carousel.Item>


  <Carousel.Item interval={1000}>
    <img
      className="d-block w-100"
      src={require('../images/slide3.jpg')} height={450} width={700}
      alt="Third slide"
    />
    <Carousel.Caption>
      <h2 class="font-weight-bold" style={{
        
        color: 'black'}}>Providing water treatement solutions and chemicals.</h2>
    </Carousel.Caption>
  </Carousel.Item>


  <Carousel.Item interval={1000}>
    <img
      className="d-block w-100"
      src={require('../images/slide4.jpg')} height={450} width={700}
      alt="Third slide"
    />
  </Carousel.Item>


  <Carousel.Item interval={1000}>
    <img
      className="d-block w-100"
      src={require('../images/slide5.jpg')} height={450} width={700}
      alt="Third slide"
    />
    <Carousel.Caption>
      <h1 class="font-weight-bolder"
      style={{color: 'black'}}>Chem Need Simplified</h1>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
      </Container>
    </div>
  )
}

export default HomeScreen