import React from 'react'
import { Container, Navbar, Nav, Col, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'


function Header() {
  const style1 = {
    backgroundColor: "White",
  };
  return (
    
    <div style={style1}>
      <header>
      <Navbar expand="lg" collapseOnSelect>
        <Container>
          
        <Col xs lg="4">
          <LinkContainer to='/'>
            <Navbar.Brand>
              <img src={require('../images/logo.jpeg')} height={80} width={200} alt=''/>
            </Navbar.Brand>
          </LinkContainer>
        </Col>
          
          {/* <LinkContainer to='/'>
            <Navbar.Brand >Kayleego Holdings</Navbar.Brand>
          </LinkContainer>
           */}
           
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end">
              
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              
              <LinkContainer to="/about-us">
                <Nav.Link >About Us</Nav.Link>
              </LinkContainer>
              
              {/* <LinkContainer to="/products">
                <Nav.Link>Products</Nav.Link>
              </LinkContainer> */}
              
              <NavDropdown title='Products'>
                
                <LinkContainer to='/water-treatement'>
                  <NavDropdown.Item>
                    Water Treatement
                  </NavDropdown.Item>
                </LinkContainer>
                
                <LinkContainer to='/hygiene-sanitation'>
                  <NavDropdown.Item>
                    Hygiene and Sanitation
                  </NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to='/coal-kayleego'>
                  <NavDropdown.Item>
                    Coal
                  </NavDropdown.Item>
                </LinkContainer>
                
              </NavDropdown>
            
              
            
              
              <LinkContainer to="/markets">
                <Nav.Link>Markets</Nav.Link>
              </LinkContainer>
              
              <LinkContainer to="/contact-us">
                <Nav.Link>Contact Us</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
          <div>
            <h4>
              Call Us today!<br/>
              <strong>
                012 006 5211
              </strong>
            </h4>
          </div>
        </Container>
        </Navbar>
    </header>
    </div>
    
  )
}

export default Header