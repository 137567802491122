import React from 'react'
import { Link} from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import {Helmet} from 'react-helmet'


function MarketScreen() {
  return (
    <div>
         <Helmet>
        <title >
            Markets
        </title>
      </Helmet>
        <Link to='/' className='btn btn-light my-3'>Go Home</Link>
        <h1 className='text-center'>
            Markets We Serve
        </h1>
        <Row sm={12} md={6} lg={4} xl={3}>
                            
          <Col >
            <Card className="my-3 p-3 rounded">
            
                {/* link the correct image */}
                <img src={require('../images/market/juice.jpg')} height={150} width={250} alt=''/>

                <Card.Body>
                
                    <Card.Title as="div">
                        <h4>Water, Juice</h4>
                    </Card.Title>
                

                    <Card.Text as="div">
                        <div className="my-3">
                        <p>CIP and manual systems, process equipment disinfection, environmental 
                         cleaning & disinfection/fogging , bottle washing, Filler CIP and foaming,
                        chemical measurement system, conveyor lubrication, biofilm control, pasteurizer 
                        treatment, drain and odour control</p>
                        </div>
                    </Card.Text>                
                </Card.Body>
            </Card>                  
          </Col>
          
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/wine-beer-liquor.jpg')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>Alcoholic Beverages</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                    <p>CIP and manual systems, process equipment disinfection, environmental cleaning &
                     disinfection/fogging , bottle washing, Filler CIP and foaming, chemical measurement 
                     system, conveyor lubrication, biofilm control, pasteurizer treatment, wort boiler 
                     descaling/tubes unblocking, drain and odour control</p>
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card>                  
          </Col>
          
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/Fresh-Produce-Collage.jpg')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>Fresh Produce</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                    <p>Manual systems cleaning, process equipment disinfection, environmental cleaning & 
                    disinfection, personal hygiene products, chemical measurement system, 
                    drain and odour control, biofilm control</p>
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card>                   
          </Col>
          
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/dairy.jpg')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>Dairy</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                    <p>CIP and manual systems, process equipment disinfection, environmental cleaning 
                    & disinfection/fogging, Filler CIP and foaming, chemical measurement system, 
                    conveyor lubrication, biofilm control, pasteurizer treatment, drain and odour control</p>
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card>                   
          </Col>  
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/redmeat.jpg')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>Red Meat</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                    <p>CIP and manual systems, process equipment disinfection, environmental cleaning 
                    & disinfection, Personal hygiene, chemical measurement system, biofilm control, 
                    drain and odour control</p>
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card>                  
          </Col>
          
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/Fisheries.jpg')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>Fisheries</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                    <p>CIP and manual systems, process equipment disinfection, environmental cleaning & 
                    disinfection, Personal hygiene, chemical measurement system, biofilm control, 
                    drain and odour control</p>
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card> 
            </Col>     

            <Col >
            <Card className="my-3 p-3 rounded">
            
                {/* link the correct image */}
                <img src={require('../images/market/food-processing.jpg')} height={150} width={250} alt=''/>

                <Card.Body>
                
                    <Card.Title as="div">
                        <h4>Food Processing</h4>
                    </Card.Title>
                

                    <Card.Text as="div">
                        <div className="my-3">
                        <p>CIP and manual systems, process equipment disinfection, environmental cleaning & 
                             disinfection, personal hygiene products, chemical measurement system, drain and
                            odour control, biofilm control</p>
                        </div>
                    </Card.Text>                
                </Card.Body>
            </Card>                  
          </Col>
          
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/chickens-poultry-farm.jpg')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>Poultry</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                    <p>Broiler farms cleaning, Broiler farms disinfection/fogging, Hatchery cleaning, hatchery 
                    disinfection/fogging, process equipment disinfection, personal hygiene products, 
                    biosecurity products, drain and odour control</p>
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card>                  
          </Col>
          
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/general-manufacturing.webp')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>General Manufacturing</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                    <p>Cooling Water, Boiler Water, Waste Water, Reverse Osmosis
                  </p>
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card>                   
          </Col>
          
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/mining.jpg')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>Mining</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                        <p>Waste Water treatment</p>
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card>                   
          </Col>  
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/municipal.jpg')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>Municipalities</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                    <p>Waste Water Treatment</p>
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card>                  
          </Col>
          
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/paper.jpg')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>Paper</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                    <p>Specialist process products ranging from conventional corrosion and 
                    deposit inhibitors to biocides and defoamers on to polymers for retention
                     and waste water treatment.</p>
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card> 
            </Col>     

            <Col >
            <Card className="my-3 p-3 rounded">
            
                {/* link the correct image */}
                <img src={require('../images/market/pharmaceuticals.jpg')} height={150} width={250} alt=''/>

                <Card.Body>
                
                    <Card.Title as="div">
                        <h4>Pharmaceuticals</h4>
                    </Card.Title>
                

                    <Card.Text as="div">
                        <div className="my-3">
                        <p>Cooling Water, Boiler Water, Waste Water, Reverse Osmosis</p>
                        </div>
                    </Card.Text>                
                </Card.Body>
            </Card>                  
          </Col>
          
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/elctricity-power.jpg')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>Power Generation</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                    <p>Cooling Water, Boiler Water, Waste Water, Reverse Osmosis
                  </p>t
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card>                  
          </Col>
          
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/steeltubes.jpg')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>Metal & Steel</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                    <p>Different flocculants and emulsion breakers as well as specific products for removal
                     of heavy metals , water reclycling, cooling water, boiler water,
                  </p>
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card>                   
          </Col>
          
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/wastewater-treatment.jpg')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>Waste water Treatment</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                    <p>Cooling Water, Boiler Water, Waste Water, Reverse Osmosis</p>
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card>                   
          </Col>  
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/automative-industry_intro.jpg')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>Automative</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                        <p>Cooling Water, Boiler Water, Waste Water</p>
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card>                  
          </Col>
          
          <Col >
          <Card className="my-3 p-3 rounded">
            
            {/* link the correct image */}
            <img src={require('../images/market/RefiningPetrochemical_306624047.2560.webp')} height={150} width={250} alt=''/>

            <Card.Body>
            
                <Card.Title as="div">
                    <h4>Petrochemical & Refinery</h4>
                </Card.Title>
            

                <Card.Text as="div">
                    <div className="my-3">
                    <p>Development of innovative problem solutions, like Scavengers for mercury removal, 
                    emulsion breakers for process water systems, effective defoamers for stripper columns, 
                    Safe and efficient cleaning and degassing additives. In refinery side also Patented ACF 
                    technology for online removal and prevention of ammonium salts, Fouling inhibitors, Fuel 
                    additives, Cleaning and degassing additives.</p>
                    </div>
                </Card.Text>                
            </Card.Body>
        </Card> 
            </Col>     
        </Row>
                 
    </div>
  )
}

export default MarketScreen